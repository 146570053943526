<template>
  <section
    :style="{
      '--default-slider-bg-mobile': data?.backgroundColorMobile?.hexa || 'transparent',
      '--default-slider-bg-desktop': data?.backgroundColor?.hexa || 'transparent',
    }"
    class="bg-[var(--default-slider-bg-mobile,transparent)] lg:bg-[var(--default-slider-bg-desktop,transparent)]"
  >
    <div class="pt-9 lg:pt-10 pb-18 lg:pb-15">
      <div class="flex">
        <div class="w-full flex-[0_0_60px] max-w-[60px] lg:flex-[0_0_140px] lg:max-w-[140px] pr-6 lg:pr-10 pl-4 lg:pl-18">
          <div class="
            default-slider-vertical-text rotate-180 origin-center whitespace-nowrap text-ellipsis overflow-hidden w-full ui-text-l font-medium
            max-h-[340px] lg2:max-h-[485px] xl:max-h-[550px] 2xl:max-h-[630px]
          ">
            {{ data.title }}
            <span v-if="data.title && data.subTitle" class="my-11">—</span>
            {{ data.subTitle }}
          </div>
        </div>
        <div class="w-full flex-[0_0_calc(100%-60px)] max-w-[calc(100%-60px)] lg:flex-[0_0_calc(100%-140px)] lg:max-w-[calc(100%-140px)]">
          <UiScrollableSlider
            :items-list="data.slides || []"
            :slides-count="data?.slidesCount?.mobile || 1.3"
            class="mobile-default-slider lg:!hidden"
            :gap-x="16"
          >
            <template #card="{ item }">
              <div class="relative">
                <div
                  class="aspect-h-15 aspect-w-11 mb-1.5 cursor-pointer"
                  :style="{
                    filter: `brightness(${data.image_brightness_filter || 100}%)`,
                  }"
                >
                  <UiLazyImg
                    class="h-full w-full"
                    :src="item.file?.url"
                    :src-mobile="item.fileMobile?.url || ''"
                    :alt="item.file?.alt || item.title || 'image'"
                    img-classes="object-cover"
                  />
                </div>
                <h3
                  class="ui-text-m font-medium tracking-[0.8px]"
                  :style="{ textAlign: data?.card_content_position || 'left' }"
                  v-html="item.title"
                ></h3>
                <p
                  v-if="item.subTitle"
                  class="mt-1 ui-text-m"
                  :style="{ textAlign: data?.card_content_position || 'left' }"
                >
                  {{ item.subTitle }}
                </p>
                <NuxtLink
                  v-if="item?.buttonUrl"
                  class="absolute inset-0"
                  :to="item.buttonUrl"
                />
              </div>
            </template>
          </UiScrollableSlider>
          <div class="max-lg:hidden">
            <Swiper
              :slides-per-view="data?.slidesCount?.mobile || 1.3"
              :space-between="0"
              :loop="false"
              :centered-slides="false"
              :initial-slide="0"
              :modules="modules"
              :navigation="navigation"
              class="w-full"
              :breakpoints="{
                [screens.md]: {
                  slidesPerView: data?.slidesCount?.desktop || 3.5,
                  centeredSlides: false,
                  initialSlide: 0,
                  spaceBetween: 40,
                },
              }"
              @swiper="initSwiper"
              @resize="onUpdateSwiper"
            >
              <SwiperSlide
                v-for="item in data.slides?.slice(0, 15)"
                :key="item.id"
                class="max-lg:px-2.5"
              >
                <div class="relative">
                  <div
                    class="aspect-h-14 aspect-w-11 mb-2.5 cursor-pointer"
                    :style="{
                      filter: `brightness(${data.image_brightness_filter || 100}%)`,
                    }"
                  >
                    <UiLazyImg
                      class="h-full w-full"
                      :src="item.file?.url"
                      :src-mobile="item.fileMobile?.url || ''"
                      :alt="item.file?.alt || item.title || 'image'"
                      img-classes="object-cover"
                    />
                  </div>
                  <h3
                    class="ui-text-l font-medium tracking-[0.8px]"
                    :style="{ textAlign: data?.card_content_position || 'left' }"
                    v-html="item.title"
                  ></h3>
                  <p
                    v-if="item.subTitle"
                    class="mt-1 ui-text-m"
                    :style="{ textAlign: data?.card_content_position || 'left' }"
                  >
                    {{ item.subTitle }}
                  </p>
                  <NuxtLink
                    v-if="item?.buttonUrl"
                    class="absolute inset-0"
                    :to="item.buttonUrl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div
        v-if="!hideArrows && data.slides?.length && swiperInited"
        class="container text-right mt-7 max-lg:hidden"
      >
        <button
          ref="prevEl"
          :aria-label="$t('previousSlide')"
          class="mr-5 inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-primary border-primary transition-all disabled:opacity-50"
        >
          <UiIcon name="arrow_left" class="size-6" />
        </button>
        <button
          ref="nextEl"
          :aria-label="$t('nextSlide')"
          class="inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-primary border-primary transition-all disabled:opacity-50"
        >
          <UiIcon name="arrow_right" class="size-6" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import { NavigationOptions } from "swiper/types";
import { debounce } from "lodash-es";
import { BlockSliderContent } from "ecom-types";
import { screens } from "~/configs/breakpointsConfig.json";

const props = defineProps<{ data: BlockSliderContent }>();

const hideArrows = ref(false);
const prevEl = ref<HTMLElement | null>(null);
const nextEl = ref<HTMLElement | null>(null);
const swiperInited = ref(false);

const debounceSwiper = debounce((params) => {
  hideArrows.value = params?.params?.slidesPerView >= (props.data?.slides?.length || 3.5);
  swiperInited.value = true;
}, 200);

function initSwiper(params: any) {
  debounceSwiper(params);
}

function onUpdateSwiper(params: any) {
  debounceSwiper(params);
}

const navigation = reactive({
  prevEl,
  nextEl,
  disabledClass: "opacity-20 cursor-not-allowed",
}) as NavigationOptions;

const modules = [Navigation];
</script>

<style scoped>
.default-slider-vertical-text {
  writing-mode: vertical-lr;
}
:deep(.mobile-default-slider) .diamondshape-slick-mobile {
  @apply scroll-pl-0;
}
:deep(.mobile-default-slider) .diamondshape-slick-mobile li {
  @apply first:ml-0 last:mr-0;
}
</style>
